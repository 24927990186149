body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.landing-container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.center-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.kickstarter-button {
  padding: 24px 40px;
  font-size: 3rem;
  font-weight: 800;
  color: white;
  background-color: #05CE78;
  border-radius: 9999px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  text-decoration: none;
}

.kickstarter-button:hover {
  background-color: #77d9b4;
}

/* Adjust button size for smaller screens */
@media (max-width: 768px) {
  .kickstarter-button {
    padding: 10px 16px;
    font-size: 1rem;
    font-weight: 800;
    max-width: 90%;
    text-align: center;
  }
}

.background-link {
  position: absolute;
  inset: 0;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
}

